<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">แก้ไขร้านชุมชน</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            v-if="store"
            @submit="onSubmitStore"
            :validation-schema="storeValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อร้าน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="id" v-model="store.id" />
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ชื่อร้าน"
                    v-model="store.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รูปภาพ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="d-flex justify-center align-center">
                    <div style="width: 256px; height: 256px; padding: 0px 3px">
                      <Field
                        type="hidden"
                        name="image_store"
                        v-model="imageLogo"
                      />
                      <div v-if="imageLogo" style="position: relative">
                        <img class="image-logo" :src="imageLogo" alt="" />
                        <button
                          @click="deleteImage"
                          class="btn btn-danger bt-delete"
                        >
                          x
                        </button>
                      </div>
                      <button
                        v-else
                        type="button"
                        class="
                          form-control
                          btn btn-outline-secondary btn-new-icon
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#modal_new_icon"
                      >
                        <img
                          src="@/assets/picture.png"
                          class="icon-ima"
                        /><br />
                        แนบรูปภาพ
                      </button>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="image_store" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->


                                                        <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >สถานะ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="status"
                    class="form-control h-auto form-control-solid py-4 px-8"
                    as="select"
                    v-model="store.status"
                  >
                    <option value="แสดง" >แสดง</option>
                    <option value="ไม่แสดง" >ไม่แสดง</option>
                   
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="status" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >บ้านเลขที่</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="house_no"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="บ้านเลขที่"
                    v-model="store.house_no"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="house_no" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >หมู่ที่</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="village_no"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="หมู่ที่"
                    v-model="store.village_no"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

               <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >ซอย</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="lane"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ซอย"
                    v-model="store.lane"
                  />
                         
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

   

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">ถนน</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="road"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ถนน"
                    v-model="store.road"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >อำเภอ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="sub_district"
                    class="form-control form-control-solid"
                    as="select"
                    v-model="data_c.amphoe"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="(item, key) in uniqueAmphoe"
                      :key="key"
                      :value="key"
                    >
                      {{ item.amphoe }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="sub_district" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ตำบล/แขวง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="district"
                    class="form-control form-control-solid"
                    as="select"
                    v-model="data_c.district"
                    @change="setZipcode(data_c.district)"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="(item, key) in uniqueDistrict"
                      :key="key"
                      :value="key"
                    >
                      {{ item.district }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="district" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รหัสไปรษณีย์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="postal_code"
                    class="form-control form-control-solid"
                    placeholder="รหัสไปรษณีย์"
                    v-model="data_c.zipcode"
                    disabled
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="postal_code" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >เบอร์โทรศัพท์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="tel"
                    class="form-control form-control-solid"
                    placeholder="เบอร์โทรศัพท์"
                    v-model="store.tel"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >เว็บไซต์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="website"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="เว็บไซต์"
                    v-model="store.website"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">Line</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="line"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Line"
                    v-model="store.line"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >Facebook</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="facebook"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Facebook"
                    v-model="store.facebook"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> แก้ไข </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
  <CropImage
    :aspectRatio="4 / 3"
    :height="1200"
    :width="1200"
    @clicked="satImageLogo"
  ></CropImage>
</template>

<script>
import CropImage from "@/components/modals/CropImage.vue";
import json from "@/core/services/DataStore.json";
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  reactive,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useStores from "@/core/services/api/travel/otop/store";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "otop-store-edit",
  components: {
    ErrorMessage,
    Field,
    Form,
    CropImage,
  },
  props: ["id"],
  setup(props) {
    const router = useRouter();
    const { store, getStore, updateStore } = useStores();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const imageLogo = ref("");
    const items = ref(json);
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const data_c = reactive({
      province: "แม่ฮ่องสอน",
      amphoe: "",
      district: "",
      zipcode: "",
    });
    const storeValidator = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก ชื่อร้าน").label("ชื่อร้าน"),
      image_store: Yup.string().required("กรุณาเลือก รูปภาพ").label("รูปภาพ"),
      house_no: Yup.string()
        .required("กรุณากรอก บ้านเลขที่")
        .label("บ้านเลขที่"),
      sub_district: Yup.string().required("กรุณาเลือก อำเภอ").label("อำเภอ"),
      district: Yup.string().required("กรุณาเลือก ตำบล").label("ตำบล"),
      postal_code: Yup.string()
        .required("กรุณาเลือก รหัสไปรษณีย์")
        .label("รหัสไปรษณีย์"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
         status: Yup.string().required("กรุณากรอก สถานะ").label("สถานะ"),
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) => item.district === result && item.amphoe === data_c.amphoe
      );
      data_c.zipcode = data[0].zipcode;
    }

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter((item) => item.province === data_c.province);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter((item) => item.amphoe === data_c.amphoe);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    const onSubmitStore = (values) => {
      Sconfirm("ยืนยัน แก้ไขร้านชุมชน", "question").then(async (result) => {
        if (result.isConfirmed) {
          if (submitButton.value) {
            // Activate indicator
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          await updateStore(values)
            .then(() => {
              SToast("success", "แก้ไข ร้านชุมชน สำเร็จ");
            })
            .catch((error) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.name) {
                  SToast("error", `${errors.name[0]}`);
                }
                if (errors.parameter) {
                  SToast("error", `${errors.parameter[0]}`);
                }
                if (errors.name && errors.parameter) {
                  SToast(
                    "error",
                    `${errors.name[0]} ,<br> ${errors.parameter[0]}`
                  );
                }
              }
            });
        }
      });
    };

    function satImageLogo(image) {
      imageLogo.value = image;
    }

    function deleteImage() {
      imageLogo.value = "";
    }

    watch(store, (store) => {
      imageLogo.value = store.image;
      data_c.amphoe = store.sub_district;
      data_c.district = store.district;
      data_c.zipcode = store.postal_code;
    });

    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.travels.otop.store.index" });
      }
      getStore(props.id);
      checkPage("otop-store-edit");
      setCurrentPageBreadcrumbs("แก้ไขร้านชุมชน", [
        "ส่งเสริมการท่องเที่ยว",
        "สินค้าชุมชน",
        "ร้านชุมชน",
      ]);
    });

    return {
      store,
      imageLogo,
      submitButton,
      onSubmitStore,
      satImageLogo,
      deleteImage,
      data_c,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
      storeValidator,
    };
  },
});
</script>
<style scoped>
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
</style>
